export default {
  LABEL: {
    TECNOLOGIA: 'Tecnologia que simplifica a jornada de',
    CREDITO: 'crédito dos agentes financeiros.',
    TERMS: 'Termos de Uso',
    PRIVACY: 'Política de Privacidade',
    ALL_RIGHTS_RESERVEDS: 'Todos os direitos reservados',
    SITE_CRDC: 'Site da CRDC',
  },
}
