<template>
  <q-layout view="hHr LpR lFr">
    <CustomDrawer />
    <q-page-container class="page-container">
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script setup>
import CustomDrawer from '@/components/customDrawer/CustomDrawer.vue'
import { onMounted } from 'vue'
import { useTypesStore } from './stores/types'
const useStoreTypes = useTypesStore()

onMounted(() => {
  useStoreTypes.fetchAllContractorTypes()
  useStoreTypes.fetchAllAddressTypes()
  useStoreTypes.fetchAllContactTypes()
  useStoreTypes.fetchAllStatusTypes()
})
</script>
<style lang="scss" scoped>
.page-container {
  background: #f6fafd !important;
  overflow: hidden;
  min-height: 92vh;
}
</style>
